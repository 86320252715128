<template>
  <div :class="type">
    <shared-show :type="type" :inputs="inputs" :lefts="leftCards" :rights="rightCards" />
  </div>
</template>

<script>
import SharedShow from '@/components/shared/show.vue'

export default {
  name: 'AttractionDetail',
  components: {
    SharedShow
  },
  data() {
    return {
      type: 'attraction',
      inputs: ['name', 'qr_code', 'ordered', 'vrtour', 'vrtour_trailer', 'description', 'content', 'navigation', 'route_map'],
      leftCards: ['artifacts', 'artifact_groups', 'groups'],
      rightCards: [
        'packages',
        'place_parent',
        'display',
        'price',
        'days',
        'hours',
        'address',
        'contact',
        'category',
        'youtube',
        'audios',
        'pictures',
        'videos'
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
